import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePanelWithHeader',{attrs:{"headerText":"Tax Rate To City"}},[_c('BaseSimpleDataTable',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.filteredExciseTaxRateToCity,"searchText":_vm.searchText,"loading":_vm.loading},scopedSlots:_vm._u([{key:"searchParams",fn:function(){return [_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"auto"}},[_c(VSwitch,{attrs:{"label":"Show Inactive"},model:{value:(_vm.showInactive),callback:function ($$v) {_vm.showInactive=$$v},expression:"showInactive"}})],1),_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"12","sm":"auto"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c(VCol,{staticClass:"mt-4 mr-4",attrs:{"cols":"12","sm":"auto"}},[_c('BaseTooltipButton',{attrs:{"small":"","icon":"mdi-plus","iconColor":"white","buttonClass":"primary"},on:{"click":function($event){return _vm.$refs.exciseTaxRateToCityForm.addExciseTaxRateToCity()}}},[_vm._v("Add Tax Rate To City ")])],1)],1)]},proxy:true},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-pencil"},on:{"click":function($event){return _vm.$refs.exciseTaxRateToCityForm.editExciseTaxRateToCity(item)}}},[_vm._v("Edit Tax Rate To City ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-delete"},on:{"click":function($event){return _vm.$refs.exciseTaxRateToCityDelete.deleteConfirm(item)}}},[_vm._v("Delete Excise Tax Rate To City ")])]}},{key:"item.IsActive",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.IsActive ? 'Yes' : 'No')+" ")]}}])}),_c('BaseDeleteConfirm',{ref:"exciseTaxRateToCityDelete",attrs:{"delete":_vm.deleteExciseTaxRateToCity},on:{"refresh":_vm.loadExciseTaxRateToCity}},[_vm._v(" Are you sure you want to delete this excise tax rate to city? ")]),_c('ExciseTaxRateToCityDialogForm',{ref:"exciseTaxRateToCityForm",on:{"refresh":_vm.loadExciseTaxRateToCity}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }