<template>
  <BasePanelWithHeader headerText="DOR Interface Email Notifications">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredEmailNotifications"
      :loading="loading"
      search
    >
      <template v-slot:prependParams> </template>
      <template v-slot:searchParams>
        <v-row>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="
                $refs.emailNotificationForm.addEmailNotification({
                  NotificationTypeId: 1,
                })
              "
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add Email Notification
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{item}">
        <BaseTooltipButton
          small
          @click="$refs.emailNotificationForm.editEmailNotification(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Email Notification
        </BaseTooltipButton>
      </template>
      <template v-slot:item.delete="{item}">
        <BaseTooltipButton
          small
          @click="$refs.emailNotificationDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Email Notification
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="emailNotificationDelete"
      :delete="deleteEmailNotification"
      @refresh="loadEmailNotifications"
    >
      Are you sure you want to delete this email notification?
    </BaseDeleteConfirm>
    <EmailNotificationDialogForm
      ref="emailNotificationForm"
      @refresh="loadEmailNotifications"
    >
    </EmailNotificationDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import EmailNotificationDialogForm from '@components/dialog/emailNotification-dialog.vue'
export default {
  components: {
    EmailNotificationDialogForm,
  },
  data: () => ({
    searchText: '',
  }),
  created() {
    this.loadEmailNotifications()
  },
  computed: {
    ...get('emailNotification', ['emailNotifications', 'loading']),
    filteredEmailNotifications() {
      var list = this.emailNotifications.filter(
        (i) => i.NotificationTypeId == 1
      )
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Email',
          align: 'center',
          sortable: true,
          value: 'Email',
          visible: true,
        },
        {
          text: 'Notification Type Id',
          align: 'center',
          sortable: true,
          value: 'NotificationTypeId',
          visible: false,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: true,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('emailNotification', [
      'deleteEmailNotification',
      'loadEmailNotifications',
    ]),
  },
}
</script>
<style></style>
