<template>
  <!-- Example usage:
  <selectMultiLuRole
    v-model="selectedUser.UserRole" (the collection we are updating)
    selfKey="UserID" (how the selectedUser is referenced in the join item)
    :selfID="selectedUser.ID" (to create new entries we need the selected users ID)
    relatedItemKey="RoleID" (this is how the lookup is referenced in the join)
    joinItemName="UserRole" (this is the name of the join (for the js class))
  ></selectMultiLuRole>
-->
  <v-select
    v-bind="$attrs"
    :items="availableItems"
    :label="label"
    v-model="selected"
    :item-text="itemText"
    item-value="ID"
    :clearable="clearable"
    :rules="rules"
    :loading="loading"
    chips
    deletable-chips
    multiple
    dense
  >
    <template
      v-for="(_, scopedSlotName) in $scopedSlots"
      v-slot:[scopedSlotName]="slotData"
    >
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </v-select>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import AuditEntityPublicFunds from '@classes/AuditEntityPublicFunds'
import CityCounty from '@classes/CityCounty'
import ExciseExceptions from '@classes/ExciseExceptions'
import ExciseTaxRateToCounty from '@classes/ExciseTaxRateToCounty'
import ProjectCollections from '@classes/ProjectCollections'
import ProjectFindings from '@classes/ProjectFindings'
export default {
  name: 'select-multi-join-luCounty',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: 'County',
    },
    value: Array,
    clearable: {
      type: Boolean,
      default: false,
    },
    includeInactive: {
      type: Boolean,
      default: false,
    },
    rules: Array,
    items: Array,
    //templateFIX: if there is more than one pick one to display
    itemText: {
      type: String,
      default: 'Name',
    },
    sortProperty: {
      type: String,
      default: null,
    },
    joinItemName: {
      type: String,
      required: true,
      //default: 'AuditEntityPublicFunds',
      //default: 'CityCounty',
      //default: 'ExciseExceptions',
      //default: 'ExciseExceptions',
      //default: 'ExciseTaxRateToCounty',
      //default: 'ProjectCollections',
      //default: 'ProjectFindings',
    },
    relatedItemKey: {
      type: String,
      required: true,
      //default: 'CountyID',
      //default: 'CountyID',
      //default: 'DeliveredToCountyID',
      //default: 'SoldFromCountyID',
      //default: 'CountyID',
      //default: 'CountyID',
      //default: 'CountyId',
    },
    selfKey: {
      type: String,
      required: true,
    },
    selfID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...get('luCounty', ['luCounty', 'loading']),
    availableItems() {
      var list = []
      var fullList = []
      if (this.items) {
        fullList = this.items
        list = this.items.filter((i) => true)
      } else {
        fullList = this.luCounty
        list = this.luCounty.filter((i) => true)
      }
      //filter for only active items
      if (!this.includeInactive) {
        list = list.filter((i) => i.IsActive)
      }
      // if existing record make sure the previous selection is available even if no longer active
      if (this.value > 0) {
        var selectedItem = fullList.find((i) => i.ID == this.value)
        if (!list.find((i) => i.ID == this.value) && selectedItem) {
          list.push(selectedItem)
        }
      }
      // sort by itemText column
      list.sort((a, b) => {
        //set the property to sort on
        let textField = this.sortProperty || this.itemText
        let sort = a[textField] > b[textField] ? 1 : -1
        return sort
      })
      return list
    },
    selected: {
      get() {
        if (this.value && this.value.length > 0) {
          return [
            ...new Set(this.value.map((item) => item[this.relatedItemKey])),
          ]
        } else {
          return []
        }
      },
      set(newValue) {
        if (this.value) {
          this.value.forEach((relation, index, object) => {
            if (!newValue.includes(relation[this.relatedItemKey])) {
              //need to remove item
              object.splice(index, 1)
            }
          })
          newValue.forEach((itemID) => {
            if (!this.value.find((i) => i[this.relatedItemKey] == itemID)) {
              // need to add item
              let item
              if (this.joinItemName == 'AuditEntityPublicFunds') {
                item = new AuditEntityPublicFunds()
              } else if (this.joinItemName == 'CityCounty') {
                item = new CityCounty()
              } else if (this.joinItemName == 'ExciseExceptions') {
                item = new ExciseExceptions()
              } else if (this.joinItemName == 'ExciseExceptions') {
                item = new ExciseExceptions()
              } else if (this.joinItemName == 'ExciseTaxRateToCounty') {
                item = new ExciseTaxRateToCounty()
              } else if (this.joinItemName == 'ProjectCollections') {
                item = new ProjectCollections()
              } else if (this.joinItemName == 'ProjectFindings') {
                item = new ProjectFindings()
              } else {
                item = {}
              }
              item[this.relatedItemKey] = itemID
              item[this.selfKey] = this.selfID
              this.value.push(item)
            }
          })
        }
      },
    },
  },
  created() {
    if (!this.items) {
      this.loadLuCounty()
    }
  },
  methods: {
    loadLuCounty: call('luCounty/loadLuCounty'),
  },
}
</script>
<style>
</style>