<template>
  <v-dialog
    persistent
    v-model="dialog"
    @keydown.esc="dialog = false"
    width="850"
    scrollable
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Special District"
                v-model="selectedLuSpecialDistrict.SpecialDistrict"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12">
              <v-text-field
                label="DOR Jurisdiction"
                v-model="selectedLuSpecialDistrict.DORJurisdiction"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <LuCounty
                label="County"
                v-model="selectedLuSpecialDistrict.CountyID"
              ></LuCounty>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-checkbox
                label="Is Active"
                v-model="selectedLuSpecialDistrict.IsActive"
                :rules="[(v) => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveLuSpecialDistrictDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import LuSpecialDistrict from '@classes/LuSpecialDistrict'
import LuCounty from '@components/select/single/LuCounty'

//templateTODO: import lookup components you plan to use
export default {
  components: {
    LuCounty,
    //templateTodo: define lookup components you are using
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedLuSpecialDistrict: new LuSpecialDistrict(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('luSpecialDistrict', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luSpecialDistrict', ['saveLuSpecialDistrict']),
    async validate() {
      this.$refs.form.validate()
    },
    editLuSpecialDistrict(entry) {
      this.selectedLuSpecialDistrict = new LuSpecialDistrict(entry)
      this.modalText = 'Edit Special District'
      this.dialog = !this.dialog
    },
    addLuSpecialDistrict() {
      this.modalText = 'Insert Special District'
      this.selectedLuSpecialDistrict = new LuSpecialDistrict()
      this.dialog = !this.dialog
    },
    saveLuSpecialDistrictDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveLuSpecialDistrict(
            this.selectedLuSpecialDistrict.removeRelated()
          ).then(
            (res) => {
              this.$emit('refresh')
              this.selectedLuSpecialDistrict = new LuSpecialDistrict()
              this.$refs.form.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuSpecialDistrict = new LuSpecialDistrict()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
