import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePanelWithHeader',{attrs:{"headerText":"DOR Interface Email Notifications"}},[_c('BaseSimpleDataTable',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.filteredEmailNotifications,"loading":_vm.loading,"search":""},scopedSlots:_vm._u([{key:"prependParams",fn:function(){return undefined},proxy:true},{key:"searchParams",fn:function(){return [_c(VRow,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"12","sm":"auto"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c(VCol,{staticClass:"mt-4 mr-4",attrs:{"cols":"12","sm":"auto"}},[_c('BaseTooltipButton',{attrs:{"small":"","icon":"mdi-plus","iconColor":"white","buttonClass":"primary"},on:{"click":function($event){return _vm.$refs.emailNotificationForm.addEmailNotification({
                NotificationTypeId: 1,
              })}}},[_vm._v("Add Email Notification ")])],1)],1)]},proxy:true},{key:"item.edit",fn:function(ref){
              var item = ref.item;
return [_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-pencil"},on:{"click":function($event){return _vm.$refs.emailNotificationForm.editEmailNotification(item)}}},[_vm._v("Edit Email Notification ")])]}},{key:"item.delete",fn:function(ref){
              var item = ref.item;
return [_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-delete"},on:{"click":function($event){return _vm.$refs.emailNotificationDelete.deleteConfirm(item)}}},[_vm._v("Delete Email Notification ")])]}}])}),_c('BaseDeleteConfirm',{ref:"emailNotificationDelete",attrs:{"delete":_vm.deleteEmailNotification},on:{"refresh":_vm.loadEmailNotifications}},[_vm._v(" Are you sure you want to delete this email notification? ")]),_c('EmailNotificationDialogForm',{ref:"emailNotificationForm",on:{"refresh":_vm.loadEmailNotifications}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }