<template>
  <v-dialog
    persistent
    v-model="dialog"
    @keydown.esc="dialog = false"
    width="850"
    scrollable
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <BaseDatePickerWithText
                label="Effective Date mm/dd/yyyy"
                v-model="selectedExciseTaxRateToCountyStatewide.EffectiveDate"
                :rules="[(v) => !!v || 'required']"
              ></BaseDatePickerWithText>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12">
              <LuCounty
                label="County"
                v-model="selectedExciseTaxRateToCountyStatewide.CountyID"
                :rules="[(v) => !!v || 'required']"
              ></LuCounty>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <LuExciseTaxRates
                label="Tax Rate"
                v-model="selectedExciseTaxRateToCountyStatewide.TaxRateID"
                :rules="[(v) => !!v || 'required']"
              ></LuExciseTaxRates>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <LuExciseTaxTypes
                label="Tax Type"
                v-model="selectedExciseTaxRateToCountyStatewide.TaxTypeID"
                :rules="[(v) => !!v || 'required']"
              ></LuExciseTaxTypes>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-checkbox
                label="Is Active"
                v-model="selectedExciseTaxRateToCountyStatewide.IsActive"
                :rules="[(v) => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveExciseTaxRateToCountyStatewideDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import ExciseTaxRateToCountyStatewide from '@classes/ExciseTaxRateToCountyStatewide'
//templateTODO: import lookup components you plan to use
import LuCounty from '@components/select/a-single/LuCounty'
import LuExciseTaxRates from '@components/select/single/LuExciseTaxRates'
import LuExciseTaxTypes from '@components/select/single/LuExciseTaxTypes'
export default {
  components: {
    //templateTodo: define lookup components you are using
    LuCounty,
    LuExciseTaxRates,
    LuExciseTaxTypes,
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedExciseTaxRateToCountyStatewide:
      new ExciseTaxRateToCountyStatewide(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('exciseTaxRateToCountyStatewide', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('exciseTaxRateToCountyStatewide', [
      'saveExciseTaxRateToCountyStatewide',
    ]),
    async validate() {
      this.$refs.form.validate()
    },
    editExciseTaxRateToCountyStatewide(entry) {
      this.selectedExciseTaxRateToCountyStatewide =
        new ExciseTaxRateToCountyStatewide(entry)
      this.modalText = 'Edit Tax Rate To State'
      this.dialog = !this.dialog
    },
    addExciseTaxRateToCountyStatewide() {
      this.modalText = 'Insert Tax Rate To State'
      this.selectedExciseTaxRateToCountyStatewide =
        new ExciseTaxRateToCountyStatewide()
      this.dialog = !this.dialog
    },
    saveExciseTaxRateToCountyStatewideDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveExciseTaxRateToCountyStatewide(
            this.selectedExciseTaxRateToCountyStatewide.removeRelated()
          ).then(
            (res) => {
              this.$emit('refresh')
              this.selectedExciseTaxRateToCountyStatewide =
                new ExciseTaxRateToCountyStatewide()
              this.$refs.form.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedExciseTaxRateToCountyStatewide =
        new ExciseTaxRateToCountyStatewide()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
