<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <EmailNotificationForm
      ref="emailNotificationForm"
      :dialog.sync="dialog"
      @refresh="$emit('refresh')"
      @itemAdded="$emit('itemAdded', $event)"
    >
    </EmailNotificationForm>
  </v-dialog>
</template>
<script>
import EmailNotificationForm from '@components/form/emailNotification-form.vue'
export default {
  components: {
    EmailNotificationForm,
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {},
  methods: {
    editEmailNotification(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.emailNotificationForm.editEmailNotification(entry)
      })
    },
    addEmailNotification(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.emailNotificationForm.addEmailNotification(entry)
      })
    },
  },
}
</script>
<style></style>
