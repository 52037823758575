import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePanelWithHeader',{attrs:{"headerText":"Tax Rates"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(VRow,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"12","sm":"auto"}},[_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c(VCol,{staticClass:"mt-4 mr-4",attrs:{"cols":"12","sm":"auto"}},[_c('BaseTooltipButton',{attrs:{"small":"","icon":"mdi-plus","iconColor":"white","buttonClass":"primary"},on:{"click":function($event){return _vm.$refs.luExciseTaxRatesForm.addLuExciseTaxRates()}}},[_vm._v("Add Tax Rates ")])],1)],1),_c(VDataTable,{staticClass:"pt-4",attrs:{"headers":_vm.filteredHeaders,"items":_vm.filteredLuExciseTaxRates,"search":_vm.searchText,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-pencil"},on:{"click":function($event){return _vm.$refs.luExciseTaxRatesForm.editLuExciseTaxRates(item)}}},[_vm._v("Edit Tax Rates ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-delete"},on:{"click":function($event){return _vm.$refs.luExciseTaxRatesDelete.deleteConfirm(item)}}},[_vm._v("Delete Excise Tax Rates ")])]}}])}),_c('BaseDeleteConfirm',{ref:"luExciseTaxRatesDelete",attrs:{"delete":_vm.deleteLuExciseTaxRates},on:{"refresh":_vm.clearLookupCacheAndReloadLuExciseTaxRates}},[_vm._v(" Are you sure you want to delete this excise tax rates? ")]),_c('LuExciseTaxRatesDialogForm',{ref:"luExciseTaxRatesForm",on:{"refresh":_vm.clearLookupCacheAndReloadLuExciseTaxRates}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }