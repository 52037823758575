<template>
  <BasePanelWithHeader headerText="Issues">
    <template v-slot:content>
      <v-row>
        <v-col cols="12" sm="auto">
          <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
        </v-col>
        <v-col class="ml-auto" cols="12" sm="auto">
          <v-text-field
            v-model="searchText"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-4 mr-4">
          <BaseTooltipButton
            small
            @click="$refs.luExciseIssuesForm.addLuExciseIssues()"
            icon="mdi-plus"
            iconColor="white"
            buttonClass="primary"
            >Add Issues
          </BaseTooltipButton>
        </v-col>
      </v-row>
      <v-data-table
        :headers="filteredHeaders"
        :items="filteredLuExciseIssues"
        :search="searchText"
        class="pt-4"
        :loading="loading"
        dense
      >
        <template v-slot:item.edit="{item}">
          <BaseTooltipButton
            small
            @click="$refs.luExciseIssuesForm.editLuExciseIssues(item)"
            iconColor="primary"
            icon="mdi-pencil"
            >Edit Issues
          </BaseTooltipButton>
        </template>
        <template v-slot:item.delete="{item}">
          <BaseTooltipButton
            small
            @click="$refs.luExciseIssuesDelete.deleteConfirm(item)"
            iconColor="primary"
            icon="mdi-delete"
            >Delete Excise Issues
          </BaseTooltipButton>
        </template>
        <template v-slot:item.SubjectToInterest="{item}">
          {{ item.SubjectToInterest ? 'Yes' : 'No' }}
        </template>
        <template v-slot:item.IsActive="{item}">
          {{ item.IsActive ? 'Yes' : 'No' }}
        </template>
      </v-data-table>
      <BaseDeleteConfirm
        ref="luExciseIssuesDelete"
        :delete="deleteLuExciseIssues"
        @refresh="clearLookupCacheAndReloadLuExciseIssues"
      >
        Are you sure you want to delete this excise issues?
      </BaseDeleteConfirm>
      <LuExciseIssuesDialogForm
        ref="luExciseIssuesForm"
        @refresh="clearLookupCacheAndReloadLuExciseIssues"
      >
      </LuExciseIssuesDialogForm>
    </template>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import LuExciseIssuesDialogForm from '@components/form/add-edit/LuExciseIssuesDialog'
export default {
  components: {
    LuExciseIssuesDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
  }),
  created() {
    this.loadLuExciseIssues()
  },
  computed: {
    ...get('luExciseIssues', ['luExciseIssues', 'loading']),
    filteredLuExciseIssues() {
      var list = this.luExciseIssues.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Issue Number',
          align: 'center',
          sortable: true,
          value: 'IssueNumber',
          visible: true,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Statute',
          align: 'center',
          sortable: true,
          value: 'Statute',
          visible: true,
        },
        {
          text: 'Rules Reg',
          align: 'center',
          sortable: true,
          value: 'RulesReg',
          visible: true,
        },
        {
          text: 'Subject To Interest',
          align: 'center',
          sortable: true,
          value: 'SubjectToInterest',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luExciseIssues', [
      'deleteLuExciseIssues',
      'loadLuExciseIssues',
      'clearLookupCacheAndReloadLuExciseIssues',
    ]),
  },
}
</script>
<style></style>
