import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"850","scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{staticClass:"elevation-3"},[_c(VCardTitle,{staticClass:"primary text-h5 white--text"},[_vm._v(" "+_vm._s(_vm.modalText)+" ")]),_c(VCardText,{staticClass:"pa-4"},[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VTextField,{attrs:{"label":"Description","rules":[function (v) { return !!v || 'required'; }]},model:{value:(_vm.selectedLuExciseProjectionType.Description),callback:function ($$v) {_vm.$set(_vm.selectedLuExciseProjectionType, "Description", $$v)},expression:"selectedLuExciseProjectionType.Description"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCheckbox,{attrs:{"label":"Is Active","rules":[function (v) { return v === true || v === false || 'required'; }]},model:{value:(_vm.selectedLuExciseProjectionType.IsActive),callback:function ($$v) {_vm.$set(_vm.selectedLuExciseProjectionType, "IsActive", $$v)},expression:"selectedLuExciseProjectionType.IsActive"}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"disabled":_vm.saving,"color":"primary"},on:{"click":_vm.saveLuExciseProjectionTypeDetails}},[_vm._v("Save")]),_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelEntry}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }