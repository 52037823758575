<template>
  <BasePanelWithHeader headerText="Tax Rates">
    <template v-slot:content>
      <v-row>
        <v-col class="ml-auto" cols="12" sm="auto">
          <v-text-field
            v-model="searchText"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-4 mr-4">
          <BaseTooltipButton
            small
            @click="$refs.luExciseTaxRatesForm.addLuExciseTaxRates()"
            icon="mdi-plus"
            iconColor="white"
            buttonClass="primary"
            >Add Tax Rates
          </BaseTooltipButton>
        </v-col>
      </v-row>
      <v-data-table
        :headers="filteredHeaders"
        :items="filteredLuExciseTaxRates"
        :search="searchText"
        class="pt-4"
        :loading="loading"
        dense
      >
        <template v-slot:item.edit="{item}">
          <BaseTooltipButton
            small
            @click="$refs.luExciseTaxRatesForm.editLuExciseTaxRates(item)"
            iconColor="primary"
            icon="mdi-pencil"
            >Edit Tax Rates
          </BaseTooltipButton>
        </template>
        <template v-slot:item.delete="{item}">
          <BaseTooltipButton
            small
            @click="$refs.luExciseTaxRatesDelete.deleteConfirm(item)"
            iconColor="primary"
            icon="mdi-delete"
            >Delete Excise Tax Rates
          </BaseTooltipButton>
        </template>
      </v-data-table>
      <BaseDeleteConfirm
        ref="luExciseTaxRatesDelete"
        :delete="deleteLuExciseTaxRates"
        @refresh="clearLookupCacheAndReloadLuExciseTaxRates"
      >
        Are you sure you want to delete this excise tax rates?
      </BaseDeleteConfirm>
      <LuExciseTaxRatesDialogForm
        ref="luExciseTaxRatesForm"
        @refresh="clearLookupCacheAndReloadLuExciseTaxRates"
      >
      </LuExciseTaxRatesDialogForm>
    </template>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import LuExciseTaxRatesDialogForm from '@components/form/add-edit/LuExciseTaxRatesDialog'
export default {
  components: {
    LuExciseTaxRatesDialogForm,
  },
  data: () => ({
    searchText: '',
  }),
  created() {
    this.loadLuExciseTaxRates()
  },
  computed: {
    ...get('luExciseTaxRates', ['luExciseTaxRates', 'loading']),
    filteredLuExciseTaxRates() {
      var list = this.luExciseTaxRates.filter((i) => true)
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Tax Rate',
          align: 'center',
          sortable: true,
          value: 'TaxRate',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luExciseTaxRates', [
      'deleteLuExciseTaxRates',
      'loadLuExciseTaxRates',
      'clearLookupCacheAndReloadLuExciseTaxRates',
    ]),
  },
}
</script>
<style></style>
