<template>
  <!--  Generated from Add_Edit_w_Lookups template-->
  <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
    <v-card class="elevation-3">
      <v-card-title class="primary text-h5 white--text">
        {{ headerText }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Email"
              v-model="selectedEmailNotification.Email"
              :rules="[(v) => !!v || 'required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="12">
              <LuNotificationType
                label="Notification Type"
                v-model="selectedEmailNotification.NotificationTypeId"
                  :rules="[v => !!v || 'required']"
              ></LuNotificationType>
            </v-col>
          </v-row> -->
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="saveEmailNotificationDetails"
          :disabled="saving"
          color="primary"
          >Save</v-btn
        >
        <v-btn v-if="inDialog" outlined color="primary" @click="cancelEntry"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import EmailNotification from '@classes/EmailNotification'
//templateTODO: import lookup components you plan to use
export default {
  components: {
    //templateTodo: define lookup components you are using
  },
  props: {
    dialog: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    valid: true,
    selectedEmailNotification: new EmailNotification(),
    headerText: 'EmailNotification',
  }),
  created() {},
  computed: {
    ...get('emailNotification', ['saving']),
    inDialog() {
      if (this.dialog === false || this.dialog === true) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...call('emailNotification', ['saveEmailNotification']),
    async validate() {
      await this.$refs.form.validate()
    },
    editEmailNotification(entry) {
      this.selectedEmailNotification = new EmailNotification(entry)
      this.headerText = 'Edit Email Notification'
    },
    addEmailNotification(entry) {
      this.headerText = 'Insert Email Notification'
      this.selectedEmailNotification = new EmailNotification(entry)
    },
    async saveEmailNotificationDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          let res = await this.saveEmailNotification(
            this.selectedEmailNotification.removeRelated()
          )
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedEmailNotification = new EmailNotification()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.$emit('update:dialog', false)
      }
    },
    cancelEntry() {
      this.selectedEmailNotification = new EmailNotification()
      this.$refs.form.resetValidation()
      this.$emit('update:dialog', false)
    },
  },
}
</script>
<style></style>
