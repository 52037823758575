<template>
  <v-dialog
    persistent
    v-model="dialog"
    @keydown.esc="dialog = false"
    width="850"
    scrollable
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Tax Rate"
                v-model="selectedLuExciseTaxRates.TaxRate"
                type="number"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveLuExciseTaxRatesDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import LuExciseTaxRates from '@classes/LuExciseTaxRates'
//templateTODO: import lookup components you plan to use
export default {
  components: {
    //templateTodo: define lookup components you are using
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedLuExciseTaxRates: new LuExciseTaxRates(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('luExciseTaxRates', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luExciseTaxRates', ['saveLuExciseTaxRates']),
    async validate() {
      this.$refs.form.validate()
    },
    editLuExciseTaxRates(entry) {
      this.selectedLuExciseTaxRates = new LuExciseTaxRates(entry)
      this.modalText = 'Edit Tax Rates'
      this.dialog = !this.dialog
    },
    addLuExciseTaxRates() {
      this.modalText = 'Insert Tax Rates'
      this.selectedLuExciseTaxRates = new LuExciseTaxRates()
      this.dialog = !this.dialog
    },
    saveLuExciseTaxRatesDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveLuExciseTaxRates(
            this.selectedLuExciseTaxRates.removeRelated()
          ).then(
            (res) => {
              this.$emit('refresh')
              this.selectedLuExciseTaxRates = new LuExciseTaxRates()
              this.$refs.form.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuExciseTaxRates = new LuExciseTaxRates()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
