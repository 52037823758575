<template>
  <BasePanelWithHeader headerText="Projection Types">
    <template v-slot:content>
      <v-row>
        <v-col cols="12" sm="auto">
          <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
        </v-col>
        <v-col class="ml-auto" cols="12" sm="auto">
          <v-text-field
            v-model="searchText"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-4 mr-4">
          <BaseTooltipButton
            small
            @click="
              $refs.luExciseProjectionTypeForm.addLuExciseProjectionType()
            "
            icon="mdi-plus"
            iconColor="white"
            buttonClass="primary"
            >Add Projection Type
          </BaseTooltipButton>
        </v-col>
      </v-row>
      <v-data-table
        :headers="filteredHeaders"
        :items="filteredLuExciseProjectionType"
        :search="searchText"
        class="pt-4"
        :loading="loading"
        dense
      >
        <template v-slot:item.edit="{item}">
          <BaseTooltipButton
            small
            @click="
              $refs.luExciseProjectionTypeForm.editLuExciseProjectionType(item)
            "
            iconColor="primary"
            icon="mdi-pencil"
            >Edit Projection Type
          </BaseTooltipButton>
        </template>
        <template v-slot:item.delete="{item}">
          <BaseTooltipButton
            small
            @click="$refs.luExciseProjectionTypeDelete.deleteConfirm(item)"
            iconColor="primary"
            icon="mdi-delete"
            >Delete Excise Projection Type
          </BaseTooltipButton>
        </template>
        <template v-slot:item.IsActive="{item}">
          {{ item.IsActive ? 'Yes' : 'No' }}
        </template>
      </v-data-table>
      <BaseDeleteConfirm
        ref="luExciseProjectionTypeDelete"
        :delete="deleteLuExciseProjectionType"
        @refresh="clearLookupCacheAndReloadLuExciseProjectionType"
      >
        Are you sure you want to delete this excise projection type?
      </BaseDeleteConfirm>
      <LuExciseProjectionTypeDialogForm
        ref="luExciseProjectionTypeForm"
        @refresh="clearLookupCacheAndReloadLuExciseProjectionType"
      >
      </LuExciseProjectionTypeDialogForm>
    </template>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import LuExciseProjectionTypeDialogForm from '@components/form/add-edit/LuExciseProjectionTypeDialog'
export default {
  components: {
    LuExciseProjectionTypeDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
  }),
  created() {
    this.loadLuExciseProjectionType()
  },
  computed: {
    ...get('luExciseProjectionType', ['luExciseProjectionType', 'loading']),
    filteredLuExciseProjectionType() {
      var list = this.luExciseProjectionType.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luExciseProjectionType', [
      'deleteLuExciseProjectionType',
      'loadLuExciseProjectionType',
      'clearLookupCacheAndReloadLuExciseProjectionType',
    ]),
  },
}
</script>
<style></style>
