<template>
  <v-dialog
    persistent
    v-model="dialog"
    @keydown.esc="dialog = false"
    width="850"
    scrollable
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Description"
                v-model="selectedLuExciseProjectionType.Description"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-checkbox
                label="Is Active"
                v-model="selectedLuExciseProjectionType.IsActive"
                :rules="[(v) => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveLuExciseProjectionTypeDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import LuExciseProjectionType from '@classes/LuExciseProjectionType'
//templateTODO: import lookup components you plan to use
export default {
  components: {
    //templateTodo: define lookup components you are using
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedLuExciseProjectionType: new LuExciseProjectionType(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('luExciseProjectionType', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luExciseProjectionType', ['saveLuExciseProjectionType']),
    async validate() {
      this.$refs.form.validate()
    },
    editLuExciseProjectionType(entry) {
      this.selectedLuExciseProjectionType = new LuExciseProjectionType(entry)
      this.modalText = 'Edit Projection Type'
      this.dialog = !this.dialog
    },
    addLuExciseProjectionType() {
      this.modalText = 'Insert Projection Type'
      this.selectedLuExciseProjectionType = new LuExciseProjectionType()
      this.dialog = !this.dialog
    },
    saveLuExciseProjectionTypeDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveLuExciseProjectionType(
            this.selectedLuExciseProjectionType.removeRelated()
          ).then(
            (res) => {
              this.$emit('refresh')
              this.selectedLuExciseProjectionType = new LuExciseProjectionType()
              this.$refs.form.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuExciseProjectionType = new LuExciseProjectionType()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
