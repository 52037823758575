<template>
  <v-container fluid>
    <v-tabs>
      <v-tab>DOR Return Import</v-tab>
      <v-tab-item>
        <ExciseDORLicReturns></ExciseDORLicReturns>
      </v-tab-item>
      <v-tab>City</v-tab>
      <v-tab-item>
        <City></City>
      </v-tab-item>
      <v-tab>County</v-tab>
      <v-tab-item>
        <County></County>
      </v-tab-item>
      <v-tab>Issues</v-tab>
      <v-tab-item>
        <ExciseIssues></ExciseIssues>
      </v-tab-item>
      <v-tab>Projection Types</v-tab>
      <v-tab-item>
        <ExciseProjectionType></ExciseProjectionType>
      </v-tab-item>
      <v-tab>Special District</v-tab>
      <v-tab-item>
        <SpecialDistrict></SpecialDistrict>
      </v-tab-item>
      <v-tab>Tax Rates</v-tab>
      <v-tab-item>
        <ExciseTaxRates></ExciseTaxRates>
      </v-tab-item>
      <v-tab>Tax Types</v-tab>
      <v-tab-item>
        <ExciseTaxTypes></ExciseTaxTypes>
      </v-tab-item>
      <v-tab>Tax Rate to City</v-tab>
      <v-tab-item>
        <ExciseTaxRateToCity></ExciseTaxRateToCity>
      </v-tab-item>
      <v-tab>Tax Rate to County</v-tab>
      <v-tab-item>
        <ExciseTaxRateToCounty></ExciseTaxRateToCounty>
      </v-tab-item>
      <v-tab>Tax Rate to State</v-tab>
      <v-tab-item>
        <ExciseTaxRateToCountyStatewide></ExciseTaxRateToCountyStatewide>
      </v-tab-item>
      <v-tab>Tax Rate to Special District</v-tab>
      <v-tab-item>
        <ExciseTaxRateToSpecialDistrict></ExciseTaxRateToSpecialDistrict>
      </v-tab-item>
      <v-tab>Transaction Types</v-tab>
      <v-tab-item>
        <ExciseTransactionType></ExciseTransactionType>
      </v-tab-item>
      <v-tab>DOR Interface Email List</v-tab>
      <v-tab-item>
        <ExciseDORInterfaceEmailList></ExciseDORInterfaceEmailList>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<script>
import City from '@components/admin/LuCityGrid'
import County from '@components/admin/LuCountyGrid'
import ExciseIssues from '@components/admin/LuExciseIssuesGrid'
import ExciseProjectionType from '@components/admin/LuExciseProjectionTypeGrid'
import SpecialDistrict from '@components/admin/LuSpecialDistrictGrid'
import ExciseTaxRates from '@components/admin/LuExciseTaxRatesGrid'
import ExciseTaxTypes from '@components/admin/LuExciseTaxTypesGrid'
import ExciseTaxRateToCity from '@components/admin/ExciseTaxRateToCityGrid'
import ExciseTaxRateToCounty from '@components/admin/ExciseTaxRateToCountyGrid'
import ExciseTaxRateToCountyStatewide from '@components/admin/ExciseTaxRateToCountyStatewideGrid'
import ExciseTaxRateToSpecialDistrict from '@components/admin/ExciseTaxRateToSpecialDistrictGrid'
import ExciseTransactionType from '@components/admin/LuExciseTransactionTypeGrid'
import ExciseDORLicReturns from '@components/search/ExciseDORLicReturns'
import ExciseDORInterfaceEmailList from '@components/admin/emailNotification-dorInterface-grid'
export default {
  page: {
    title: 'Excise Admin',
  },
  components: {
    City,
    County,
    ExciseIssues,
    ExciseProjectionType,
    SpecialDistrict,
    ExciseTaxRates,
    ExciseTaxTypes,
    ExciseTaxRateToCity,
    ExciseTaxRateToCounty,
    ExciseTaxRateToCountyStatewide,
    ExciseTaxRateToSpecialDistrict,
    ExciseTransactionType,
    ExciseDORLicReturns,
    ExciseDORInterfaceEmailList,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
