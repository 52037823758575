<template>
  <BasePanelWithHeader headerText="Tax Rate To State">
    <template v-slot:content>
      <v-row>
        <v-col cols="12" sm="auto">
          <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
        </v-col>
        <v-col class="ml-auto" cols="12" sm="auto">
          <v-text-field
            v-model="searchText"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-4 mr-4">
          <BaseTooltipButton
            small
            @click="
              $refs.exciseTaxRateToCountyStatewideForm.addExciseTaxRateToCountyStatewide()
            "
            icon="mdi-plus"
            iconColor="white"
            buttonClass="primary"
            >Add Tax Rate To County
          </BaseTooltipButton>
        </v-col>
      </v-row>
      <v-data-table
        :headers="filteredHeaders"
        :items="filteredExciseTaxRateToCountyStatewide"
        :search="searchText"
        class="pt-4"
        split
        :loading="loading"
        dense
      >
        <template v-slot:item.edit="{item}">
          <BaseTooltipButton
            small
            @click="
              $refs.exciseTaxRateToCountyStatewideForm.editExciseTaxRateToCountyStatewide(
                item
              )
            "
            iconColor="primary"
            icon="mdi-pencil"
            >Edit Tax Rate To County
          </BaseTooltipButton>
        </template>
        <template v-slot:item.delete="{item}">
          <BaseTooltipButton
            small
            @click="
              $refs.exciseTaxRateToCountyStatewideDelete.deleteConfirm(item)
            "
            iconColor="primary"
            icon="mdi-delete"
            >Delete Excise Tax Rate To County
          </BaseTooltipButton>
        </template>
        <template v-slot:item.IsActive="{item}">
          {{ item.IsActive ? 'Yes' : 'No' }}
        </template>
      </v-data-table>
      <BaseDeleteConfirm
        ref="exciseTaxRateToCountyStatewideDelete"
        :delete="deleteExciseTaxRateToCountyStatewide"
        @refresh="loadExciseTaxRateToCountyStatewide"
      >
        Are you sure you want to delete this excise tax rate to county?
      </BaseDeleteConfirm>
      <ExciseTaxRateToCountyStatewideDialogForm
        ref="exciseTaxRateToCountyStatewideForm"
        @refresh="loadExciseTaxRateToCountyStatewide"
      >
      </ExciseTaxRateToCountyStatewideDialogForm>
    </template>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import ExciseTaxRateToCountyStatewideDialogForm from '@components/form/add-edit/ExciseTaxRateToCountyStatewideDialog'
export default {
  components: {
    ExciseTaxRateToCountyStatewideDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
  }),
  created() {
    this.loadExciseTaxRateToCountyStatewide()
  },
  computed: {
    ...get('exciseTaxRateToCountyStatewide', [
      'exciseTaxRateToCountyStatewide',
      'loading',
    ]),
    filteredExciseTaxRateToCountyStatewide() {
      var list = this.exciseTaxRateToCountyStatewide.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Tax Rate ID',
          align: 'center',
          sortable: true,
          value: 'TaxRateID',
          visible: false,
        },
        {
          text: 'County ID',
          align: 'center',
          sortable: true,
          value: 'CountyID',
          visible: false,
        },
        {
          text: 'Tax Type ID',
          align: 'center',
          sortable: true,
          value: 'TaxTypeID',
          visible: false,
        },
        {
          text: 'Effective Date',
          align: 'center',
          sortable: true,
          value: 'EffectiveDate',
          visible: true,
        },
        {
          text: 'County',
          align: 'center',
          sortable: true,
          value: 'County.Name',
          visible: true,
        },
        {
          text: 'Tax Rate',
          align: 'center',
          sortable: true,
          value: 'TaxRate.TaxRate',
          visible: true,
        },
        {
          text: 'Tax Type',
          align: 'center',
          sortable: true,
          value: 'TaxType.TaxType',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('exciseTaxRateToCountyStatewide', [
      'deleteExciseTaxRateToCountyStatewide',
      'loadExciseTaxRateToCountyStatewide',
    ]),
  },
}
</script>
<style></style>
