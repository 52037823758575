<template>
  <BasePanelWithHeader headerText="Tax Types">
    <template v-slot:content>
      <v-row>
        <v-col cols="12" sm="auto">
          <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
        </v-col>
        <v-col class="ml-auto" cols="12" sm="auto">
          <v-text-field
            v-model="searchText"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-4 mr-4">
          <BaseTooltipButton
            small
            @click="$refs.luExciseTaxTypesForm.addLuExciseTaxTypes()"
            icon="mdi-plus"
            iconColor="white"
            buttonClass="primary"
            >Add Tax Types
          </BaseTooltipButton>
        </v-col>
      </v-row>
      <v-data-table
        :headers="filteredHeaders"
        :items="filteredLuExciseTaxTypes"
        :search="searchText"
        class="pt-4"
        :loading="loading"
        dense
      >
        <template v-slot:item.edit="{item}">
          <BaseTooltipButton
            small
            @click="$refs.luExciseTaxTypesForm.editLuExciseTaxTypes(item)"
            iconColor="primary"
            icon="mdi-pencil"
            >Edit Tax Types
          </BaseTooltipButton>
        </template>
        <template v-slot:item.delete="{item}">
          <BaseTooltipButton
            small
            @click="$refs.luExciseTaxTypesDelete.deleteConfirm(item)"
            iconColor="primary"
            icon="mdi-delete"
            >Delete Excise Tax Types
          </BaseTooltipButton>
        </template>
        <template v-slot:item.IsActive="{item}">
          {{ item.IsActive ? 'Yes' : 'No' }}
        </template>
      </v-data-table>
      <BaseDeleteConfirm
        ref="luExciseTaxTypesDelete"
        :delete="deleteLuExciseTaxTypes"
        @refresh="clearLookupCacheAndReloadLuExciseTaxTypes"
      >
        Are you sure you want to delete this excise tax types?
      </BaseDeleteConfirm>
      <LuExciseTaxTypesDialogForm
        ref="luExciseTaxTypesForm"
        @refresh="clearLookupCacheAndReloadLuExciseTaxTypes"
      >
      </LuExciseTaxTypesDialogForm>
    </template>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import LuExciseTaxTypesDialogForm from '@components/form/add-edit/LuExciseTaxTypesDialog'
export default {
  components: {
    LuExciseTaxTypesDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
  }),
  created() {
    this.loadLuExciseTaxTypes()
  },
  computed: {
    ...get('luExciseTaxTypes', ['luExciseTaxTypes', 'loading']),
    filteredLuExciseTaxTypes() {
      var list = this.luExciseTaxTypes.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Tax Type',
          align: 'center',
          sortable: true,
          value: 'TaxType',
          visible: true,
        },
        {
          text: 'Project Detail ID',
          align: 'center',
          sortable: true,
          value: 'ProjectDetailID',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luExciseTaxTypes', [
      'deleteLuExciseTaxTypes',
      'loadLuExciseTaxTypes',
      'clearLookupCacheAndReloadLuExciseTaxTypes',
    ]),
  },
}
</script>
<style></style>
