import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{staticClass:"elevation-3"},[_c(VCardTitle,{staticClass:"primary text-h5 white--text"},[_vm._v(" "+_vm._s(_vm.headerText)+" ")]),_c(VCardText,{staticClass:"pa-4"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Email","rules":[function (v) { return !!v || 'required'; }]},model:{value:(_vm.selectedEmailNotification.Email),callback:function ($$v) {_vm.$set(_vm.selectedEmailNotification, "Email", $$v)},expression:"selectedEmailNotification.Email"}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"disabled":_vm.saving,"color":"primary"},on:{"click":_vm.saveEmailNotificationDetails}},[_vm._v("Save")]),(_vm.inDialog)?_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelEntry}},[_vm._v("Cancel")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }