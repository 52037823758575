<template>
  <BasePanelWithHeader headerText="City">
    <template v-slot:content>
      <v-row>
        <v-col cols="12" sm="auto">
          <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
        </v-col>
        <v-col class="ml-auto" cols="12" sm="auto">
          <v-text-field
            v-model="searchText"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-4 mr-4">
          <BaseTooltipButton
            small
            @click="$refs.luCityForm.addLuCity()"
            icon="mdi-plus"
            iconColor="white"
            buttonClass="primary"
            >Add City
          </BaseTooltipButton>
        </v-col>
      </v-row>
      <v-data-table
        :headers="filteredHeaders"
        :items="filteredLuCity"
        :search="searchText"
        class="pt-4"
        :loading="loading"
        dense
      >
        <template v-slot:item.edit="{item}">
          <BaseTooltipButton
            small
            @click="$refs.luCityForm.editLuCity(item)"
            iconColor="primary"
            icon="mdi-pencil"
            >Edit City
          </BaseTooltipButton>
        </template>
        <template v-slot:item.delete="{item}">
          <BaseTooltipButton
            small
            @click="$refs.luCityDelete.deleteConfirm(item)"
            iconColor="primary"
            icon="mdi-delete"
            >Delete City
          </BaseTooltipButton>
        </template>
        <template v-slot:item.IsActive="{item}">
          {{ item.IsActive ? 'Yes' : 'No' }}
        </template>
        <template v-slot:item.Incorporated="{item}">
          {{ item.Incorporated ? 'Yes' : 'No' }}
        </template>
      </v-data-table>
      <BaseDeleteConfirm
        ref="luCityDelete"
        :delete="deleteLuCity"
        @refresh="clearLookupCacheAndReloadLuCity"
      >
        Are you sure you want to delete this city?
      </BaseDeleteConfirm>
      <LuCityDialogForm
        ref="luCityForm"
        @refresh="clearLookupCacheAndReloadLuCity"
      >
      </LuCityDialogForm>
    </template>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import LuCityDialogForm from '@components/form/add-edit/LuCityDialog'
export default {
  components: {
    LuCityDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
  }),
  created() {
    this.loadLuCity()
  },
  computed: {
    ...get('luCity', ['luCity', 'loading']),
    filteredLuCity() {
      var list = this.luCity.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      list = list.map((item) => {
        let name = item.Counties.map((i) => i.Name)
        return {
          ...item,
          counties: name.join(', '),
        }
      })
      return list
    },

    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'City',
          align: 'center',
          sortable: true,
          value: 'City',
          visible: true,
        },
        {
          text: 'Population',
          align: 'center',
          sortable: true,
          value: 'Population',
          visible: true,
        },
        {
          text: 'DOR Jurisdiction',
          align: 'center',
          sortable: true,
          value: 'DORJurisdiction',
          visible: true,
        },
        {
          text: 'Incorporated',
          align: 'center',
          sortable: true,
          value: 'Incorporated',
          visible: true,
        },
        {
          text: 'Counties',
          align: 'center',
          sortable: true,
          value: 'counties',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luCity', [
      'deleteLuCity',
      'loadLuCity',
      'clearLookupCacheAndReloadLuCity',
    ]),
  },
}
</script>
<style></style>
