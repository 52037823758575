import EmailNotification from './EmailNotification'
const defaults = {
  //Id is required
  Id: 0,
  //Description is required
  Description: '',
    }
export default class LuNotificationType {
  constructor(objIn = {}) {
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Description = obj.Description
    this.EmailNotifications = []
    if (obj.EmailNotifications) {
      obj.EmailNotifications.forEach((item) => {
        this.EmailNotifications.push(new EmailNotification(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.EmailNotifications = []
    if (this.EmailNotifications) {
      this.EmailNotifications.forEach((item) => {
        obj.EmailNotifications.push(item instanceof EmailNotification? item.removeRelated(): item)
      })
    }
    return this.equalsDefault() ? null : obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.EmailNotifications = null
    return this.equalsDefault() ? null : obj
  }
  equalsDefault() {
    let obj = Object.assign({}, this)
    let compare = {
      Id: obj.Id,
      Description: obj.Description,
    }
    return JSON.stringify(defaults) === JSON.stringify(compare)
  }
}
