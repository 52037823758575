<template>
  <BasePanelWithHeader headerText="Tax Rate To City">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredExciseTaxRateToCity"
      :searchText="searchText"
      :loading="loading"
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col cols="12" sm="auto">
            <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
          </v-col>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="$refs.exciseTaxRateToCityForm.addExciseTaxRateToCity()"
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add Tax Rate To City
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{item}">
        <BaseTooltipButton
          small
          @click="$refs.exciseTaxRateToCityForm.editExciseTaxRateToCity(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Tax Rate To City
        </BaseTooltipButton>
      </template>
      <template v-slot:item.delete="{item}">
        <BaseTooltipButton
          small
          @click="$refs.exciseTaxRateToCityDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Excise Tax Rate To City
        </BaseTooltipButton>
      </template>
      <template v-slot:item.IsActive="{item}">
        {{ item.IsActive ? 'Yes' : 'No' }}
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="exciseTaxRateToCityDelete"
      :delete="deleteExciseTaxRateToCity"
      @refresh="loadExciseTaxRateToCity"
    >
      Are you sure you want to delete this excise tax rate to city?
    </BaseDeleteConfirm>
    <ExciseTaxRateToCityDialogForm
      ref="exciseTaxRateToCityForm"
      @refresh="loadExciseTaxRateToCity"
    >
    </ExciseTaxRateToCityDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import ExciseTaxRateToCityDialogForm from '@components/form/add-edit/ExciseTaxRateToCityDialog'
export default {
  components: {
    ExciseTaxRateToCityDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
  }),
  created() {
    this.loadExciseTaxRateToCity()
  },
  computed: {
    ...get('exciseTaxRateToCity', ['exciseTaxRateToCity', 'loading']),
    filteredExciseTaxRateToCity() {
      var list = this.exciseTaxRateToCity.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Tax Rate ID',
          align: 'center',
          sortable: true,
          value: 'TaxRateID',
          visible: false,
        },
        {
          text: 'City ID',
          align: 'center',
          sortable: true,
          value: 'CityID',
          visible: false,
        },
        {
          text: 'Tax Type ID',
          align: 'center',
          sortable: true,
          value: 'TaxTypeID',
          visible: false,
        },
        {
          text: 'Effective Date',
          align: 'center',
          sortable: true,
          value: 'EffectiveDate',
          visible: true,
        },
        {
          text: 'City',
          align: 'center',
          sortable: true,
          value: 'City.City',
          visible: true,
        },
        {
          text: 'Tax Rate',
          align: 'center',
          sortable: true,
          value: 'TaxRate.TaxRate',
          visible: true,
        },
        {
          text: 'Tax Type',
          align: 'center',
          sortable: true,
          value: 'TaxType.TaxType',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('exciseTaxRateToCity', [
      'deleteExciseTaxRateToCity',
      'loadExciseTaxRateToCity',
    ]),
  },
}
</script>
<style></style>
