import LuNotificationType from './LuNotificationType'
const defaults = {
  //Id is required
  Id: 0,
  //Email is required
  Email: '',
  //NotificationTypeId is required
  NotificationTypeId: null,
    }
export default class EmailNotification {
  constructor(objIn = {}) {
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Email = obj.Email
    this.NotificationTypeId = obj.NotificationTypeId
    this.NotificationType = new LuNotificationType(obj.NotificationType || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.NotificationType = null
    return this.equalsDefault() ? null : obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.NotificationType = null
    return this.equalsDefault() ? null : obj
  }
  equalsDefault() {
    let obj = Object.assign({}, this)
    let compare = {
      Id: obj.Id,
      Email: obj.Email,
      NotificationTypeId: obj.NotificationTypeId,
    }
    return JSON.stringify(defaults) === JSON.stringify(compare)
  }
}
